import styled from '@emotion/styled'
import { Card as RebassCard } from 'rebass'

export const Card = styled(RebassCard)`
  transition: ${(props) => props.theme.basicTransition};

  &:hover {
    h3 {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`
