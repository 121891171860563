import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { IContentfulBlogPostMin } from '../interfaces'

import { Mq } from '../themes/default'
import { UnstyledGatsbyLink } from '../atoms/link'
import { Card } from '../atoms/card'
import { H3 } from '../atoms/heading'
import { Paragraph, SmallTitle } from '../atoms/text'

const CardContent = styled.div`
  background: ${(props) => props.theme.colors.lightBrown};
  padding: 1rem;

  ${Mq.md} {
    padding: 1.5rem;
  }
`

const BlogSmallTitle = styled(SmallTitle)`
  margin-bottom: 0.8rem;

  &:after {
    content: '';
    display: block;
    width: 3rem;
    height: 2px;
    margin-top: 1rem;
    background-color: ${(props) => props.theme.colors.lightBlue};
  }
`

const BlogImg = styled(GatsbyImage)`
  transition: ${(props) => props.theme.basicTransition};
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    filter: grayscale(0);
  }

  ${Mq.md} {
    filter: grayscale(100%);
  }
`

export const BlogCard = ({
  blogPost,
}: {
  blogPost: IContentfulBlogPostMin
}) => (
  <Card mx={[2, 3]}>
    <UnstyledGatsbyLink to={`/blog/${blogPost.slug}`}>
      <BlogImg
        image={blogPost.featuredMedia.gatsbyImageData}
        alt={blogPost.title}
      />
      <CardContent>
        <BlogSmallTitle>{blogPost.publishDate}</BlogSmallTitle>
        <H3>{blogPost.title}</H3>
        <Paragraph>{blogPost.seoDescription.seoDescription}</Paragraph>
      </CardContent>
    </UnstyledGatsbyLink>
  </Card>
)
